import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
import { useDrop, useDrag } from 'react-dnd';
import TreeItem from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import { Trans } from '@lingui/macro';

import { useSelector } from 'react-redux';
import {
  ExpandedFolderIconOutlined,
  ExpandedFolderIcon,
  CollapsedFolderIcon,
  MoreHorizIcon,
  CollapsedFolderIconOutlined,
  UserIcon,
} from '~misc/icons';
import { getLangValue } from '~common/app.utils';
import { BaseFile, FileType } from '~common/content.types';
import { combineRefs } from '~common/utils/fn.utils';
import {
  getAcceptedDndTypes,
  getDndType,
  useOnNodeDrop,
} from '~common/misc/drag/utils';
import { dndTypes } from '~common/misc/drag/constants';
import { useGetFolderTreeQuery } from '~common/content.api';

type NodeContainer<T extends BaseFile> = {
  node: T;
  depth: number;
  children: NodeContainer<T>[];
  hideIcon?: boolean;
};

export type ExtendedNodeContainer<T extends BaseFile> = NodeContainer<T> & {
  parents?: T[];
};

interface TreeItemStyles {
  bold?: boolean;
}

interface CommonProps<T extends BaseFile> extends React.AriaAttributes {
  treeContent: NodeContainer<T>;
  getNodeStyles?: (node: T) => TreeItemStyles;
  selectedItems?: (T & { node: { parents?: BaseFile[] } })[];
  expandRoot?: boolean;
  expandSelected?: boolean;
  showRoot?: boolean;
  allowDrag?: boolean;
  type?: 'folder' | 'archive';
  disabledNode?: (node: T, parents: T[]) => boolean;
  foldersExpanded?: string[];
  setFoldersExpanded?: (ids: string[]) => void;
}

interface MultiSelectProps<T extends BaseFile> extends CommonProps<T> {
  onSelectItem: (ids: string[], nodes?: ExtendedNodeContainer<T>[]) => void;
  multiSelect: true;
}

interface SingleSelectProps<T extends BaseFile> extends CommonProps<T> {
  onSelectItem?: (id: string, node?: ExtendedNodeContainer<T>) => void;
  multiSelect?: boolean;
}

interface Props<T extends BaseFile> extends CommonProps<T> {
  onSelectItem?:
    | ((id: string, node?: ExtendedNodeContainer<T>) => void)
    | ((ids: string[], nodes?: ExtendedNodeContainer<T>[]) => void);
  multiSelect?: boolean;
}

interface TreeItemProps<T extends BaseFile> extends Props<T> {
  expanded: string[];
  selectedIds: string[];
  selectedParentIds: string[];
  parents: T[];
  nodesById: React.MutableRefObject<Record<string, ExtendedNodeContainer<T>>>;
}

// TODO: moveContent should be optional and set by parent component?
// TODO: defaultExpanded={['3']}
// NOTE: Keyboard navigation fails if using custom components (has to use TreeView and TreeItem from material-ui)
function FileTree<T extends BaseFile>(props: SingleSelectProps<T>): JSX.Element;
function FileTree<T extends BaseFile>(props: MultiSelectProps<T>): JSX.Element;
function FileTree<T extends BaseFile>({
  treeContent,
  getNodeStyles,
  onSelectItem,
  selectedItems,
  expandRoot,
  expandSelected = true,
  showRoot,
  allowDrag = true,
  multiSelect = undefined,
  type = 'folder',
  disabledNode,
  foldersExpanded,
  setFoldersExpanded,
  ...listProps
}: Props<T>) {
  /** Unfolds the three structure so that the nodes, their children and parents
   * can be accessible with the node id */
  const nodesById = useRef(
    treeContent.children.reduce(
      (acc, child) => ({
        ...acc,
        [child.node.id]: { ...child, parents: [treeContent.node] },
      }),
      {} as Record<string, ExtendedNodeContainer<T>>
    )
  );

  /** Using expanded stored in redux */
  const defaultExpanded =
    expandRoot && treeContent.node.id ? [treeContent.node.id] : [];
  const [internalExpanded, setInternalExpanded] =
    useState<string[]>(defaultExpanded);

  const expanded = foldersExpanded ?? internalExpanded ?? defaultExpanded;
  const setExpanded = (ids: string[]) =>
    setFoldersExpanded ? setFoldersExpanded(ids) : setInternalExpanded(ids);

  const selectedIds = selectedItems ? selectedItems.map(item => item.id) : [];
  const selectedParentIds = selectedItems
    ? selectedItems.flatMap(item => item.node.parents?.map(p => p.id) ?? [])
    : [];

  useEffect(() => {
    if (selectedIds.length > 0) {
      // Add current item and all parents to the expanded set
      const all = selectedParentIds.concat(
        expanded,
        expandSelected ? selectedIds : []
      );
      setExpanded([...new Set(all)]);
    }
  }, [selectedIds.join(',')]);

  const onNodeToggle = (event: any, nodeIds: string[]) => {
    // the node has been selected, don't toggle it here
    if (event.key !== 'Enter') {
      setExpanded(
        expandRoot && treeContent?.node?.id
          ? [...new Set([...nodeIds, treeContent.node.id])]
          : nodeIds
      );
    }
  };

  const onNodeSelect = (event: any, nodeIds: string | string[]) => {
    // Do nothing if 'selecting' an Show more-item
    if (!Array.isArray(nodeIds) && nodeIds.startsWith('expand-')) return;
    if (Array.isArray(nodeIds))
      nodeIds = nodeIds.filter(id => !id.startsWith('expand-'));

    const selectedNodes = !Array.isArray(nodeIds)
      ? nodesById.current[nodeIds]
      : nodeIds.map(id => nodesById.current[id]);
    // Clicking on the expand/collapse icon shouldn't select the node
    if (
      !event.currentTarget
        .querySelector('.icon-container')
        ?.contains(event.target)
    )
      onSelectItem?.(
        nodeIds as string & string[],
        selectedNodes as ExtendedNodeContainer<T> & ExtendedNodeContainer<T>[]
      );
  };

  // Dragging from MUI5 TreeView is a known issue
  // https://github.com/mui/material-ui/issues/29518#issuecomment-990760866
  // Currently @mui/lab version 5.0.0-alpha.94
  const ref = useCallback((el: Element | null) => {
    const listener = (e: FocusEvent) => {
      // Try to detect if we are tabbing to the tree
      if (el !== e.target) e.stopImmediatePropagation();
    };
    el?.addEventListener('focusin', listener);
    return () => el?.removeEventListener('focusin', listener);
  }, []);

  return (
    <TreeView
      ref={ref}
      selected={multiSelect ? selectedIds : []}
      expanded={expanded}
      onNodeToggle={onNodeToggle}
      onNodeSelect={onNodeSelect}
      defaultExpandIcon={<CollapsedFolderIconOutlined />}
      defaultCollapseIcon={<ExpandedFolderIconOutlined />}
      multiSelect={multiSelect as true | undefined}
      {...listProps}
    >
      {treeContent && treeContent.node && (
        <TreeItemContent
          treeContent={{ ...treeContent, hideIcon: expandRoot }}
          type={type}
          getNodeStyles={getNodeStyles}
          onSelectItem={onSelectItem}
          selectedIds={selectedIds}
          selectedParentIds={selectedParentIds}
          allowDrag={allowDrag}
          expanded={expanded}
          disabledNode={disabledNode}
          parents={[]}
          nodesById={nodesById}
        />
      )}
    </TreeView>
  );
}

const TreeItemContent = <T extends BaseFile>({
  treeContent,
  type,
  getNodeStyles,
  onSelectItem,
  selectedIds,
  selectedParentIds,
  allowDrag,
  expanded,
  parents,
  disabledNode,
  nodesById,
}: TreeItemProps<T>) => {
  let { node, children, hideIcon } = treeContent;
  const { id, fileType } = node;

  const isExpanded = expanded?.includes(id);

  const [visibleChildrenCount, setVisibleChildrenCount] = useState(100);

  const language = useSelector(state => state.app.settings?.language);
  const { data } = useGetFolderTreeQuery(
    { rootId: id, language, type, depth: 2 },
    {
      skip: !isExpanded,
    }
  );

  if (data) {
    children = data.children as NodeContainer<T>[];
    nodesById.current = {
      ...nodesById.current,
      ...children.reduce(
        (acc, child) => ({
          ...acc,
          [child.node.id]: { ...child, parents: [...parents, node] },
        }),
        {} as Record<string, ExtendedNodeContainer<T>>
      ),
    };
  }

  let icon: React.ReactNode;
  if (
    hideIcon ||
    fileType === 'nt:cart' ||
    fileType === 'nt:linkedFolder' ||
    (children || []).length === 0
  ) {
    icon = <></>;
  } else if (
    (selectedParentIds && expanded && selectedParentIds.includes(id)) ||
    selectedIds?.includes(id)
  ) {
    if (expanded?.includes(id)) {
      icon = <ExpandedFolderIcon />;
    } else {
      icon = <CollapsedFolderIcon />;
    }
  }

  const visibleChildren = children?.slice(0, visibleChildrenCount);
  const hasMore = visibleChildren?.length !== children?.length;

  const onShowMore = () => {
    setVisibleChildrenCount(
      Math.min(visibleChildrenCount + 100, children?.length ?? 0)
    );
  };

  const showMore = hasMore ? (
    <TreeItem
      nodeId={`expand-${id}`}
      icon={<MoreHorizIcon />}
      label={
        <div>
          <Trans>Show more</Trans>
        </div>
      }
      onKeyDown={(e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
          onShowMore();
        }
      }}
      onClick={() => onShowMore()}
    />
  ) : null;

  return (
    <>
      {parents.length === 0 && children ? (
        <>
          {visibleChildren.map(
            child =>
              (type !== 'archive' ||
                child.node.fileType !== 'nt:linkedFolder') && (
                <TreeItemContent
                  treeContent={child}
                  type={type}
                  getNodeStyles={getNodeStyles}
                  onSelectItem={onSelectItem}
                  allowDrag={allowDrag}
                  selectedIds={selectedIds}
                  selectedParentIds={selectedParentIds}
                  expanded={expanded}
                  parents={[node]}
                  disabledNode={disabledNode}
                  nodesById={nodesById}
                />
              )
          )}
          {showMore}
        </>
      ) : (
        <TreeItem
          key={id}
          disabled={disabledNode?.(node, parents)}
          className={
            selectedIds.includes(node.id)
              ? 'Mui-selected-item'
              : selectedParentIds && selectedParentIds.includes(node.id)
              ? 'Mui-selected-item-parent'
              : undefined
          }
          classes={{ iconContainer: 'icon-container' }}
          aria-selected={
            selectedIds?.includes(node.id) ||
            (selectedParentIds && selectedParentIds.includes(node.id))
          }
          icon={icon}
          // keyup is fired too late (can catch an event fired by clicking a button in modal)
          onKeyDown={(e: React.KeyboardEvent) => {
            // select the node on enter and not just toggle
            if (e.key === 'Enter' && onSelectItem) {
              onSelectItem(
                node.id as string & string[],
                nodesById.current[node.id] as ExtendedNodeContainer<T> &
                  ExtendedNodeContainer<T>[]
              );
            }
          }}
          nodeId={id}
          label={
            <TreeLabel
              label={getLangValue(node.namesByLang) || node.name}
              styles={getNodeStyles ? getNodeStyles(node) : {}}
              id={id}
              fileNode={node}
              parents={[node, ...parents]}
              groupedWorkspaces={fileType === ('workspaceGroup' as FileType)}
              draggable={allowDrag || false}
            />
          }
        >
          {children
            ? visibleChildren.map(
                child =>
                  (type !== 'archive' ||
                    child.node.fileType !== 'nt:linkedFolder') && (
                    <TreeItemContent
                      treeContent={child}
                      type={type}
                      getNodeStyles={getNodeStyles}
                      onSelectItem={onSelectItem}
                      allowDrag={allowDrag}
                      selectedIds={selectedIds}
                      selectedParentIds={selectedParentIds}
                      expanded={expanded}
                      parents={[node, ...parents]}
                      disabledNode={disabledNode}
                      nodesById={nodesById}
                    />
                  )
              )
            : null}

          {showMore}
        </TreeItem>
      )}
    </>
  );
};

interface TreeLabelProps {
  label: string;
  styles: TreeItemStyles;
  id: string;
  fileNode: BaseFile;
  parents: BaseFile[];
  groupedWorkspaces?: boolean;
  draggable: boolean;
}

const TreeLabel = ({
  label,
  id,
  styles,
  fileNode,
  parents,
  groupedWorkspaces,
  draggable,
}: TreeLabelProps) => {
  const user = useSelector(state => state.app.user);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const onDrop = useOnNodeDrop(fileNode, { parents, groupedWorkspaces });

  const [{ isOver, canDrop }, drop] = useDrop({
    // Special case for dropping to current user's grouping
    accept:
      groupedWorkspaces && user?.username && fileNode.id.endsWith(user.username)
        ? [dndTypes.WORKSPACE]
        : getAcceptedDndTypes(fileNode, parents),
    drop: onDrop,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: draggable && monitor.canDrop(),
    }),
  });

  const [, drag] = useDrag({
    item: {
      itemIds: [id],
      name,
      showCustomDragLayer: false,
      type: getDndType(fileNode, parents),
    },
    type: getDndType(fileNode, parents),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: draggable && !groupedWorkspaces,
  });

  const isDropHover = isOver && canDrop;

  useEffect(() => {
    // parent is stateless so we have to access dom directly
    if (wrapperRef?.current?.parentElement?.parentElement?.parentElement) {
      const elem = wrapperRef.current.parentElement.parentElement.parentElement;
      isDropHover
        ? elem.classList.add('drag-hover')
        : elem.classList.remove('drag-hover');
    }
  }, [isDropHover]);

  return (
    <LabelWrapper ref={combineRefs(drag, drop, wrapperRef)} {...styles}>
      {groupedWorkspaces ? <UserIcon /> : null}
      {label}
      {fileNode.recursiveFileCountStatus &&
      fileNode.recursiveFileCountStatus ===
        'NOT_SUPPORTED' ? null : fileNode.recursiveFileCountStatus === 'OK' ? (
        ' (' + fileNode.recursiveFileCount + ')'
      ) : fileNode.recursiveFileCountStatus === 'PENDING' ? (
        <Loader />
      ) : null}
    </LabelWrapper>
  );
};

const LabelWrapper = styled.div<TreeItemStyles>`
  font-weight: ${({ bold }) => (bold ? 500 : 'inherit')};
  display: flex;
  align-items: center;
  gap: ${p => p.theme.spacing(0.5)};

  & > svg {
    color: ${p => p.theme.palette.grey[500]};
  }
`;

const dots = keyframes`
  0%, 40% {
    content: '.';
  }
  60% {
    content: '..';
  }
  80%, 100% {
    content: '...';
  }
`;

const Loader = styled.span`
  &::after {
    display: inline-block;
    width: 15px;
    content: '';
    animation: ${dots} 1s steps(3, end) infinite;
  }
`;

export default FileTree;
