import React from 'react';
import Tooltip from '@mui/material/Tooltip';

import Button from '~common/inputs/Button';
import IconButton from '~common/inputs/IconButton';
import MenuButton, {
  BasicOption,
  MenuOption,
  ModalOption,
} from '~common/navigation/MenuButton';
import ModalButton from '~common/navigation/ModalButton';

export type BasicActionOption<T = string> = Required<
  Pick<BasicOption<T>, 'onSelect'>
> &
  Omit<BasicOption<T>, 'onSelect'>;

export type ActionOption<T = string> =
  | BasicActionOption<T>
  | MenuOption<T>
  | ModalOption;

type ButtonProps = Omit<
  React.ComponentPropsWithRef<typeof Button>,
  'onClick' | 'disabled' | 'startIcon'
> & {
  type: 'button';
};

type IconButtonProps = Omit<
  React.ComponentPropsWithRef<typeof IconButton>,
  'onClick' | 'disabled' | 'aria-label'
> & {
  type: 'icon';
};

type Props<T> = (ButtonProps | IconButtonProps) & {
  option: ActionOption<T>;
};

/** A component that renders and action option as a button.
 * The same option should be passable as an option to MenuButton. */
export function ActionOptionButton<T>({ option, ...props }: Props<T>) {
  if (option.hidden) return null;

  if ('modalType' in option)
    return <ModalActionButton {...props} option={option} />;
  else if ('options' in option)
    return <MenuActionButton {...props} option={option} />;
  else return <BasicActionButton {...props} option={option} />;
}

type BasicActionProps<T> = Omit<Props<T>, 'option'> & {
  option: BasicActionOption<T>;
};

export function BasicActionButton<T>({
  option,
  type,
  ...buttonProps
}: BasicActionProps<T>) {
  return type === 'button' ? (
    <Button
      onClick={() => option.onSelect()}
      startIcon={option.icon}
      data-test={option.value}
      disabled={option.disabled}
      variant="contained"
      {...buttonProps}
    >
      {option.title}
    </Button>
  ) : (
    <Tooltip title={option.title} disableInteractive>
      <IconButton
        onClick={() => option.onSelect()}
        data-test={option.value}
        aria-label={option.title}
        disabled={option.disabled}
        size="small"
        color="primary"
        {...buttonProps}
      >
        {option.icon}
      </IconButton>
    </Tooltip>
  );
}

type MenuActionProps<T> = Omit<Props<T>, 'option'> & {
  option: MenuOption<T>;
};

export function MenuActionButton<T>({
  option,
  type,
  ...buttonProps
}: MenuActionProps<T>) {
  return (
    <MenuButton
      {...buttonProps}
      icon={type === 'icon' ? option.icon : undefined}
      startIcon={type !== 'icon' ? option.icon : undefined}
      options={option.options}
      onSelect={option.onSelect}
      disabled={option.disabled}
    >
      {type !== 'icon' ? option.title : null}
    </MenuButton>
  );
}

type ModalActionProps = Omit<Props<unknown>, 'option'> & {
  option: ModalOption;
};

export function ModalActionButton({
  option,
  type,
  ...buttonProps
}: ModalActionProps) {
  return (
    <ModalButton
      {...buttonProps}
      modalType={option.modalType}
      modalProps={option.modalProps}
      isIconButton={type === 'icon'}
      startIcon={option.icon}
    >
      {type === 'icon' ? option.icon : option.title}
    </ModalButton>
  );
}
