import React, { useState, CSSProperties } from 'react';
import styled from 'styled-components';
import Select, { SelectProps } from '@mui/material/Select';
import { t } from '@lingui/macro';
import {
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Checkbox,
  ListSubheader,
  InputLabelProps,
} from '@mui/material';
import { VisuallyHidden } from 'react-aria';
import FormLabel, { FormLabelProps } from './FormLabel';
import { getMuiVariant } from '~utils/form.utils';
import { LocalizedText, determineIfObjectOrString } from '~common/app.utils';
import { Chip, ChipList } from '~common/items/Chips';
import Button from '~inputs/Button';
import { FunctionComponent } from '~common/utils/types.utils';
import ButtonBar from '~common/sections/ButtonBar';

export interface Option<T> {
  name: string;
  value: T;
  disabled?: boolean;
  type?: string;
  nameRenderer?: FunctionComponent<{ name: string }>;
}

interface Props<T> {
  id?: string;
  options: Option<T>[];
  optionsLang?: string;
  multiple?: boolean;
  notEmpty?: boolean;
  value: T | T[];
  onChange: (event: Event & { target: { value: T; name: string } }) => void;
  fullWidth?: boolean;
  showEmpty?: boolean;
  error?: boolean;
  // TODO: remove variant and use outlined fields everywhere
  variant?: 'outlined' | 'standard';
  labelText?: string | LocalizedText;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  helperText?: string;
  selectProps?: SelectProps;
  labelProps?: Partial<FormLabelProps>;
  InputLabelProps?: InputLabelProps;
  style?: CSSProperties;
}

function DropDown<T extends string | number>(
  {
    id,
    options: initialOptions,
    optionsLang,
    multiple = false,
    value: initialValue,
    onChange,
    fullWidth,
    showEmpty = true,
    variant,
    labelText,
    disabled,
    readOnly,
    required,
    error,
    helperText,
    selectProps,
    labelProps,
    InputLabelProps,
    ...rest
  }: Props<T>,
  ref: React.ForwardedRef<HTMLSelectElement>
) {
  const [open, setOpen] = useState(false);
  const options: Option<T>[] =
    !multiple && showEmpty
      ? [{ value: '' as T, name: t`Empty` }, ...initialOptions]
      : initialOptions;
  // default value for multi-select is nothing selected
  // and for single-select the first value (which is empty if thats allowed)
  let value = initialValue ?? (multiple ? [] : options[0]?.value ?? '');
  // make sure the value is array if multi-select
  if (multiple && !Array.isArray(value)) value = value ? [value] : [];

  const formVariant = variant === 'outlined';

  const Label = () => {
    if (labelText) {
      return (
        <FormLabel
          label={
            determineIfObjectOrString(labelText) ? labelText.value : labelText
          }
          disabled={disabled}
          required={required}
          {...labelProps}
        />
      );
    }
    return (
      <VisuallyHidden>
        <FormLabel
          label={`${id}-label`}
          disabled={disabled}
          required={required}
          {...labelProps}
        />
      </VisuallyHidden>
    );
  };

  return (
    <FormControl disabled={disabled} error={!!error} ref={ref as any} {...rest}>
      <InputLabel
        variant={'standard'}
        shrink={false}
        style={{ top: '-2.5rem' }}
        htmlFor={id}
        id={`${id}-label`}
        {...InputLabelProps}
      >
        <Label />
      </InputLabel>
      <StyledSelect
        id={id}
        fullWidth={fullWidth}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        value={value}
        renderValue={
          multiple
            ? (selected: string[]) =>
                formVariant ? (
                  <ChipList>
                    {selected.map(value => (
                      <Chip
                        key={value}
                        label={options.find(v => v.value === value)?.name}
                        small
                      />
                    ))}
                  </ChipList>
                ) : (
                  selected.join(', ')
                )
            : undefined
        }
        multiple={multiple}
        onChange={onChange}
        variant={getMuiVariant(variant) ?? 'standard'}
        aria-label={`${
          labelText
            ? determineIfObjectOrString(labelText)
              ? labelText.value
              : labelText
            : id
        } ${t`dropdown`}`}
        inputProps={{
          readOnly,
        }}
        IconComponent={readOnly ? 'div' : undefined}
        MenuProps={{
          MenuListProps: { lang: optionsLang },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        style={{
          marginTop: labelText ? '16px' : '0px',
        }}
        SelectDisplayProps={{
          'aria-required': required,
          'aria-labelledby': `${id}-label`,
        }}
        {...selectProps}
      >
        {options &&
          options.map(
            ({ name, value: optionValue, type, nameRenderer: C, disabled }) => {
              return type === 'header' ? (
                <StyledListSubheader>{name}</StyledListSubheader>
              ) : (
                <MenuItem
                  disabled={disabled}
                  key={optionValue}
                  value={optionValue}
                >
                  {multiple && formVariant && (
                    <Checkbox
                      checked={(value as T[]).includes(optionValue as T)}
                    />
                  )}
                  {C ? <C name={name} /> : name}
                </MenuItem>
              );
            }
          )}
        {multiple && (
          <StyledButtonBar>
            <Button
              variant="outlined"
              onClick={() => setOpen(false)}
            >{t`Close`}</Button>
          </StyledButtonBar>
        )}
      </StyledSelect>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

const StyledSelect = styled(Select)`
  pointer-events: ${p => (p.inputProps?.readOnly ? 'none' : 'auto')};
  & .MuiOutlinedInput-input {
    background-color: ${p => p.theme.palette.common.white};
    padding: ${p => p.theme.spacing(1.5, 1)};
  }
`;

/** Default `ListSubheader` can be selected as an option, this is
 * fixed in MUI 5.8.0: https://github.com/mui/material-ui/pull/25567 */
const StyledListSubheader = styled(ListSubheader)`
  pointer-events: none;
  && {
    background-color: ${p => p.theme.palette.common.white};
    border-top: 1px solid ${p => p.theme.palette.grey[300]};
  }
`;

const StyledButtonBar = styled(ButtonBar)`
  margin-top: 10px;
`;

export default React.forwardRef(DropDown) as <T extends string | number>(
  props: Props<T> & { ref?: React.ForwardedRef<HTMLSelectElement> }
) => ReturnType<typeof DropDown>;
