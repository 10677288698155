import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import TreeView from '@mui/lab/TreeView';
import { useTheme } from 'styled-components';

import CategoryTreeItem from './CategoryTreeItem';
import { TreeMetaField } from '~common/content.types';
import {
  CollapsedFolderIconOutlined,
  ExpandedFolderIconOutlined,
} from '~common/misc/icons';
import { TreeNode } from '~common/content.utils';
import { Lang } from '~common/utils/i18n';

interface Props {
  data: TreeMetaField;
  selectedNodes: string[];
  setSelectedNodes: (nodes: string[]) => void;
  disabledNodes?: string[];
  readOnly?: boolean;
  multiple?: boolean;
  language?: Lang;
}

const getMetaFieldSelectedParents = (
  nodesById: Record<string, TreeNode>,
  tree: TreeNode,
  selectedIds: string[]
) => {
  const selected = [...selectedIds].map(id => nodesById[id]).filter(Boolean);
  return new Set(
    selected.reduce(
      (arr, node) => [...arr, ...node.parentIds],
      [
        // Root is open by default
        tree.id,
      ]
    )
  );
};

export const CategoryTree = ({
  data,
  disabledNodes,
  selectedNodes,
  setSelectedNodes,
  readOnly,
  language: initialLanguage,
  multiple = true,
}: Props) => {
  const userLanguage =
    useSelector(state => state.app.settings?.language) ?? 'fi';
  const language = initialLanguage ?? userLanguage;
  const tree = data.tree;

  const [expanded, setExpanded] = useState(() => {
    return 'nodesById' in data
      ? getMetaFieldSelectedParents(
          data.nodesById,
          tree as TreeNode,
          selectedNodes
        )
      : new Set<string>();
  });

  const expandedArr = useMemo(() => [...expanded], [expanded]);

  const theme = useTheme();

  const renderTree = (node: TreeNode, parents: TreeNode[] = []) => {
    const isExpanded = expanded.has(node.id);

    const getChildren = (node: TreeNode): TreeNode[] => {
      const arr: TreeNode[] = [...(node.children as TreeNode[])];
      for (const child of node.children) {
        const children = getChildren(child);
        arr.push(...children);
      }
      return arr;
    };

    const selectedChildren = getChildren(node).filter(child =>
      selectedNodes.includes(child.id)
    );

    return (
      <CategoryTreeItem
        node={node}
        parents={[]}
        setExpanded={setExpanded}
        language={language}
        selectedNodes={selectedNodes}
        multiple={multiple}
        setSelectedNodes={setSelectedNodes}
        disabledNodes={disabledNodes}
        theme={theme}
        readOnly={readOnly}
        baseFile={node}
        selectedChildren={selectedChildren}
        isExpanded={isExpanded}
      >
        {node.children.map(child => renderTree(child, [...parents, node]))}
      </CategoryTreeItem>
    );
  };

  return (
    <TreeView
      defaultExpandIcon={<CollapsedFolderIconOutlined />}
      defaultCollapseIcon={<ExpandedFolderIconOutlined />}
      onNodeToggle={(e, nodes) => setExpanded(new Set(nodes))}
      expanded={expandedArr}
    >
      {renderTree(tree)}
    </TreeView>
  );
};

export default CategoryTree;
