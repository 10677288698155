import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';

import CategoryTree from '../CategoryTree';
import FolderSearchTree from '../FolderSearchTree';
import { search } from './model';
import { Typography } from '~common/misc/Typography';
import Button from '~inputs/Button';
import { CloseIcon } from '~common/misc/icons';
import { useActions } from '~common/utils/hooks.utils';
import { FolderTreeEntry, TreeMetaField } from '~common/content.types';
import { partition } from '~common/utils/fn.utils';

interface Props {
  onSearch: () => void;
  criteriaId: string;
  title: string;
  treeData: FolderTreeEntry | TreeMetaField;
  useTags?: boolean;
  /** The depth of nodes to be separated by OR-operators */
  orLevels?: number[];
  facet?: Record<string, number>;
}

export const SearchTreeInput = ({
  onSearch,
  criteriaId,
  title,
  treeData,
  useTags,
  orLevels,
  facet,
}: Props) => {
  const criteria = useSelector(state => state.search.criteria);
  const tags = useSelector(state => state.search.tags);
  const { updateCriteria, updateTags } = useActions(search.actions);

  const setSelectedTreeNodes = (nodes: string[]) => {
    if (useTags) {
      const newTags = [
        ...tags.filter(tag => tag.id !== criteriaId),
        ...nodes.map(node => ({ id: criteriaId, value: node })),
      ];
      updateTags(newTags);
      updateCriteria({ tags: newTags });
    } else {
      let value = nodes;
      if (orLevels && 'nodesById' in treeData) {
        const [orNodes, andNodes] = partition(nodes, node =>
          orLevels.includes(treeData.nodesById[node].parentIds.length)
        );
        // Combine orNodes with the same parent into a single query parameter
        const orNodesByParent = orNodes.reduce((acc, node) => {
          const parent = treeData.nodesById[node].parentId;
          if (!parent) return acc;
          if (acc[parent]) acc[parent].push(node);
          else acc[parent] = [node];
          return acc;
        }, {} as Record<string, string[]>);
        value = [
          ...andNodes,
          ...Object.values(orNodesByParent).map(nodes => nodes.join('|||')),
        ];
      }
      updateCriteria({
        ...criteria,
        [criteriaId]: value,
      });
    }
    onSearch();
  };

  const selectedNodes: string[] = useTags
    ? tags.filter(tag => tag.id === criteriaId).map(tag => tag.value)
    : criteria[criteriaId]?.map(node => (node as string).split('|||')).flat() ??
      [];

  const treeNodeCount = selectedNodes?.length ?? 0;

  const categoryTree = 'nodesById' in treeData;

  const disabledNodes =
    facet && 'nodesById' in treeData
      ? Object.values(treeData.nodesById)
          .filter(({ id }) => !facet?.[id] || facet[id] === 0)
          // Selected nodes and their parents should not be disabled
          .filter(node => !selectedNodes?.includes(node.id))
          .filter(
            node =>
              !selectedNodes?.some(selectedNode =>
                treeData.nodesById[selectedNode]?.parentIds.includes(node.id)
              )
          )
          // Nodes on the same orLevel with a selected node sharing a parent should not be disabled
          .filter(
            node =>
              !orLevels ||
              !orLevels.includes(node.parentIds.length) ||
              !selectedNodes?.some(
                selectedNode =>
                  treeData.nodesById[selectedNode]?.parentId === node.parentId
              )
          )
      : [];
  const disabledNodeIds = disabledNodes.map(({ id }) => id);

  return (
    <div>
      <TreeHeader>
        <Typography variant="h2">{title}</Typography>
        {treeNodeCount > 0 && (
          <ClearButton
            startIcon={<CloseIcon />}
            size={'small'}
            variant="text"
            onClick={() => setSelectedTreeNodes([])}
          >
            <Trans>Clear</Trans>
          </ClearButton>
        )}
      </TreeHeader>
      {categoryTree ? (
        <CategoryTree
          data={treeData}
          selectedNodes={selectedNodes}
          setSelectedNodes={setSelectedTreeNodes}
          disabledNodes={disabledNodeIds}
        />
      ) : (
        <FolderSearchTree
          data={treeData}
          selectedNodes={selectedNodes}
          setSelectedNodes={setSelectedTreeNodes}
        />
      )}
    </div>
  );
};

const TreeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
`;

const ClearButton = styled(Button)`
  padding: 4px !important;
  font-size: 0.9rem !important;
  text-transform: none !important;
  font-weight: initial;

  .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
  }
`;
