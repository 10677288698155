import { Customer } from '~common/app.types';
import { getLangValue } from '~common/app.utils';
import { File, FileAttachment } from '~common/content.types';

export function getAttachmentName(
  att: FileAttachment,
  customerAttachmentTypes: Customer['fileAttachmentTypesById']
) {
  const attachmentTypeId =
    att.propertiesById['nibo:custom-material-type'] &&
    Number.parseInt(att.propertiesById['nibo:custom-material-type']);

  return (
    getLangValue(customerAttachmentTypes?.[attachmentTypeId]) ??
    getLangValue(att.namesByLang) ??
    att.name
  );
}

export function getFileAttachments(file: File) {
  return file.attachments?.filter(att => att.type === 'attachment.') ?? [];
}

export function getFileAttachmentTypeOptions(
  folder?: File,
  customerAttachmentTypes?: Customer['fileAttachmentTypesById']
) {
  return customerAttachmentTypes && folder
    ? Object.keys(customerAttachmentTypes)
        .filter(
          id =>
            folder.propertiesById[`nibo:custom-material-shared${id}`] === 'true'
        )
        .map(key => ({
          value: Number.parseInt(key),
          name: getLangValue(customerAttachmentTypes[key]),
        }))
    : [];
}
